/* Import Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.App {
  font-family: 'Arial', sans-serif;
  background-color: #0F0F0F;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
}

.App-logo {
  height: 60px;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;  /* Spacing between elements */
}

.white-shadow {
  box-shadow: 0px 0px 15px 5px rgba(255, 255, 255, 0.9);
  display: inline-block;  /* Ensures the shadow applies correctly */
}

button {
  padding: 10px 20px;
  border: none;
  background-color: #1A1A1A;
  color: #E7E7E7;
  border-radius: 5px;
  cursor: pointer;
}

